import { useState } from "react";
import { Routes, Route } from "react-router-dom";
// import Topbar from "./components/Topbar";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import CSV from "./components/CSV";
// import PDF from "./components/PDF";
import UnitTesting from "./components/UnitTesting";
import Settings from "./components/Settings";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(false);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isSidebar={setIsSidebar} />
          <main className="content">
            {/*<Topbar setIsSidebar={setIsSidebar} />*/}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/CSV" element={<CSV />} />
              {/*<Route path="/PDF" element={<PDF />} />*/}
              <Route path="/UnitTesting" element={<UnitTesting />} />
              <Route path="/Settings" element={<Settings />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

import { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, useTheme, CircularProgress, Autocomplete } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { tokens } from "../theme";
import { setSetting, getChannelInfo } from "../utils/helper";

const SelectData = ({ handleChannelChange }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false);
    const [channelInfo, setChannelInfo] = useState([]);
    const currentServerChannelId = JSON.parse(sessionStorage.getItem("currentServerChannelId"));

    useEffect(() => {
        setLoading(true);

        getChannelInfo().then(data => {
            console.log("SelectData channelInfo: " + JSON.stringify(data));

            if (data.result !== "error") {
                console.log("SelectData channelInfo channels: " + JSON.stringify(data.Channels));

                setChannelInfo(data.Channels);
            }
            setLoading(false);
        });
    }, []);

    const checkoutSchema = yup.object().shape({
        channelNumber: yup
            .number()
            .required("required")
    });

    const initialValues = {
        channelNumber: ((currentServerChannelId !== null && currentServerChannelId !== "") ? currentServerChannelId : 4),
    };

    const handleFormSubmit = (values) => {
        setLoading(true);

        sessionStorage.setItem("previousServerChannelId", currentServerChannelId);
        sessionStorage.setItem("currentServerChannelId", values.channelNumber);

        setSetting().then(data => {
            setLoading(false);
            handleChannelChange(values.channelNumber);
        });
    }

    return (
        <Box>
            {/* LOADING SPINNER */}
            {loading ? <Box
                sx={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    transform: "translate(0, 0)",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fffffff5",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    ml: "0",
                    zIndex: 3,
                    borderRadius: "20px",
                }}
            >
                <CircularProgress
                    color="info"
                    size={70}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 45px)",
                        left: "calc(50% - 45px)",
                    }}
                />
                <Typography
                    id="progress description"
                    variant="h4"
                    color={colors.blueAccent[500]}
                    fontWeight="normal"
                    sx={{
                        position: "absolute",
                        top: "calc(75% - 25px)",
                        left: "calc(50% - 45px)",
                    }}
                >
                    Loading
                </Typography>
            </Box> : null}
            {/* HEADER */}
            <Box
                backgroundColor={colors.primary[400]}
                p="15px"
            >
                <Typography
                    id="title"
                    variant="h4"
                    color={colors.grey[100]}
                    fontWeight="normal"
                    sx={{ m: "0 0 5px 0" }}
                >
                    Channel Number
                </Typography>
                <Typography
                    id="description"
                    variant="h6"
                    color={colors.greenAccent[400]}
                >
                    Enter the channel number.
                </Typography>
            </Box>
            {/* BODY */}
            <Box
                p="15px"
            >
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {(props) => {
                        return (
                            <Form>
                                <Autocomplete
                                    id="channelNumber"
                                    fullWidth
                                    options={channelInfo?.map((data) => data.ChannelID) ?? []}
                                    getOptionLabel={(option) => {
                                        let data = channelInfo.find((data) => data.ChannelID == option);
                                        return (
                                            `${data?.Description ?? ""} ${data?.ChannelID ? "[" + data?.ChannelID + "]" : ""}`
                                        );
                                    }}
                                    value={props.values.channelNumber || null}
                                    onChange={(e, value) => props.setFieldValue("channelNumber", value)}
                                    onBlur={props.handleBlur}
                                    isOptionEqualToValue={(option, value) =>
                                        option?.channelNumber === value?.channelNumber
                                    }
                                    renderInput={(params) => (
                                        <Field
                                            as={TextField}
                                            {...params}
                                            label="Channel Number"
                                            name="channelNumber"
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            helperText={<ErrorMessage name="channelNumber" />}
                                            error={props.errors.channelNumber && props.touched.channelNumber}
                                        />
                                    )}
                                />

                                {/*<TextField*/}
                                {/*    label="Channel Number"*/}
                                {/*    name="channelNumber"*/}
                                {/*    fullWidth*/}
                                {/*    variant="outlined"*/}
                                {/*    margin="dense"*/}
                                {/*    value={props.values.channelNumber}*/}
                                {/*    onChange={props.handleChange}*/}
                                {/*    onBlur={props.handleBlur}*/}
                                {/*    helperText={<ErrorMessage name="channelNumber" />}*/}
                                {/*    error={props.errors.channelNumber && props.touched.channelNumber}*/}
                                {/*    required*/}
                                {/*/>*/}

                                <Button
                                    // variant="contained"
                                    type="submit"
                                    // color="primary"
                                    fullWidth
                                    sx={{
                                        // my: "15px"
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "5px 15px",
                                        textTransform: "uppercase",
                                        ":hover": {
                                            backgroundColor: colors.grey[100],
                                            color: colors.blueAccent[700],
                                        },
                                    }}
                                >
                                    UPDATE
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};

export default SelectData;
import {Box, Button, TextField, Typography, useTheme} from "@mui/material";
import { Formik, Form, Field, FieldArray } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import {tokens} from "../theme";

const RemovePoint = ({ unit, point }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const handleFormSubmit = (values) => {
        console.log(values);
    };

    return (
        <Box m="20px">
            <Box
                mb="30px"
            >
                <Typography
                    id="title"
                    variant="h1"
                    color={colors.grey[100]}
                    fontWeight="normal"
                    sx={{ m: "0 0 5px 0" }}
                >
                    REMOVE POINT
                </Typography>
                <Typography
                    id="description"
                    variant="h5"
                    color={colors.greenAccent[400]}
                >
                    {`Remove Point for unit ${unit}`}
                </Typography>
            </Box>

            {/*<Formik*/}
            {/*    onSubmit={handleFormSubmit}*/}
            {/*    initialValues={initialValues}*/}
            {/*    validationSchema={checkoutSchema}*/}
            {/*>*/}
            {/*    {({*/}
            {/*          values,*/}
            {/*          errors,*/}
            {/*          touched,*/}
            {/*          handleBlur,*/}
            {/*          handleChange,*/}
            {/*          handleSubmit,*/}
            {/*      }) => (*/}
            {/*        <Form onSubmit={handleSubmit}>*/}
            {/*            <Box*/}
            {/*                display="grid"*/}
            {/*                gap="30px"*/}
            {/*                gridTemplateColumns="repeat(4, minmax(0, 1fr))"*/}
            {/*                sx={{*/}
            {/*                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    variant="filled"*/}
            {/*                    type="text"*/}
            {/*                    label="New Point"*/}
            {/*                    onBlur={handleBlur}*/}
            {/*                    onChange={handleChange}*/}
            {/*                    value={values.newPoint}*/}
            {/*                    name="newPoint"*/}
            {/*                    error={!!touched.newPoint && !!errors.newPoint}*/}
            {/*                    helperText={touched.newPoint && errors.newPoint}*/}
            {/*                    sx={{ gridColumn: "span 2" }}*/}
            {/*                />*/}
            {/*                <FieldArray*/}
            {/*                    fullWidth*/}
            {/*                    // variant="filled"*/}
            {/*                    // type="text"*/}
            {/*                    label="Points"*/}
            {/*                    onBlur={handleBlur}*/}
            {/*                    onChange={handleChange}*/}
            {/*                    // value={values.email}*/}
            {/*                    name="points"*/}
            {/*                    render={arrayHelpers => (*/}
            {/*                        <div>*/}
            {/*                            {values.points && values.points.length > 0 ? (*/}
            {/*                                values.points.map((point, index) => (*/}
            {/*                                    <div key={index}>*/}
            {/*                                        <Field name={`points.${index}`} />*/}
            {/*                                        <button*/}
            {/*                                            type="button"*/}
            {/*                                            onClick={() => arrayHelpers.remove(index)}*/}
            {/*                                        >*/}
            {/*                                            -*/}
            {/*                                        </button>*/}
            {/*                                        <button*/}
            {/*                                            type="button"*/}
            {/*                                            onClick={() => arrayHelpers.insert(index, '')}*/}
            {/*                                        >*/}
            {/*                                            +*/}
            {/*                                        </button>*/}
            {/*                                    </div>*/}
            {/*                                ))*/}
            {/*                            ) : (*/}
            {/*                                <button type="button" onClick={() => arrayHelpers.push('')}>*/}
            {/*                                    Add a Point*/}
            {/*                                </button>*/}
            {/*                            )}*/}
            {/*                            <div>*/}
            {/*                                <button type="submit">Submit</button>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    )}*/}
            {/*                    // error={!!touched.email && !!errors.email}*/}
            {/*                    // helperText={touched.email && errors.email}*/}
            {/*                    sx={{ gridColumn: "span 4" }}*/}
            {/*                />*/}
            {/*            </Box>*/}
            {/*            <Box display="flex" justifyContent="end" mt="20px">*/}
            {/*                <Button type="submit" color="secondary" variant="contained">*/}
            {/*                    Create New User*/}
            {/*                </Button>*/}
            {/*            </Box>*/}
            {/*        </Form>*/}
            {/*    )}*/}
            {/*</Formik>*/}
        </Box>
    );
};

const phoneRegExp =
    /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
    newPoint: yup.string().required("required"),
    points: yup.array()
        .of(
            yup.object().shape({
                point: yup.string().min(4, 'too short').required('Required'),
            })
        )
        .required("required")
        .min(3, 'Minimum of 3 friends'),
});

const initialValues = {
    newPoint: "",
    points: [
        "TRIP",
        "BAS",
        "MODULATING VALVE"
    ],
};

export default RemovePoint;

import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, progress }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            width="100%"
        >
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Box>
                    <Typography
                        variant="h6"
                        fontWeight="400"
                        sx={{
                            color: "#696969",
                        }}
                    >
                        {title}
                    </Typography>
                </Box>
                <Box>
                    {progress !== false && (
                        <ProgressCircle
                            progress={progress}
                            size="20"
                        />
                    )}
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Box>
                    <Typography
                        variant="h4"
                        sx={{
                            color: colors.grey[1000]
                        }}
                    >
                        {subtitle}
                    </Typography>
                </Box>
                <Box>
                    {progress !== false && (
                        <Typography
                            variant="h6"
                            fontStyle="italic"
                            sx={{
                                color: colors.greenAccent[500]
                            }}
                        >
                            {(progress * 100).toFixed(2)}%
                        </Typography>
                    )}
                </Box>
            </Box>

        </Box>
    );
};

export default StatBox;

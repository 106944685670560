import { useState, useEffect, useRef } from "react";
import { Box, Button, CircularProgress, TextField, Typography, useTheme, Autocomplete } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { tokens } from "../theme";
import { getDataById, escapeSlash } from "../utils/helper";
import { CSVLink } from "react-csv";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const CSV = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:768px)");
    const [unitData, setUnitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [exportData, setExportData] = useState([]);
    const [exportHeader, setExportHeader] = useState([]);
    const [exportFilename, setExportFilename] = useState('');
    const csvLink = useRef();

    useEffect(() => {
        setLoading(true);

        getDataById('', 'local').then(localData => {
            setUnitData(localData);
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        if (exportData.length > 0 && exportHeader.length > 0 && exportFilename !== '') {
            csvLink.current.link.click();

            setExportData([]);
            setExportHeader([]);
            setExportFilename('');
        }
    }, [ exportData, exportHeader, exportFilename ]);

    const checkoutSchema = yup.object().shape({
        unitId: yup
            .string()
            .required("required")
    });
    const initialValues = {
        unitId: "",
    };
    const handleFormSubmit = (values) => {
        setLoading(true);

        let selectedUnit = values.unitId;
        let selectedData = unitData.filter(data => data.unitId === selectedUnit);
        let data = [];

        for (let i = 0; i < selectedData.length; i++) {
            for (let j = 0; j < selectedData[i].points.length; j++) {
                data.push({
                    "channelId": selectedData[i].channelId,
                    "deviceId": selectedData[i].deviceId,
                    "interfaceName": escapeSlash(selectedData[i].interfaceName),
                    "unitId": escapeSlash(selectedData[i].unitId),
                    "unitName": escapeSlash(selectedData[i].unitName),
                    "pointId": escapeSlash(selectedData[i].points[j].pointId),
                    "pointName": escapeSlash(selectedData[i].points[j].pointName),
                    "type": selectedData[i].points[j].type,
                    "initialValue": selectedData[i].points[j].initialValue,
                    "initialTimestamp": selectedData[i].points[j].initialTimestamp,
                    "testValue": selectedData[i].points[j].testValue,
                    "testTimestamp": selectedData[i].points[j].testTimestamp,
                    "finalValue": selectedData[i].points[j].finalValue,
                    "finalTimestamp": selectedData[i].points[j].finalTimestamp,
                    "started": selectedData[i].points[j].started,
                    "tested": selectedData[i].points[j].tested,
                    "testResult": selectedData[i].points[j].testResult,
                    "ended": selectedData[i].points[j].ended,
                })
            }
        }
        setExportData(data);

        let header = [
            { label: "Channel ID", key: "channelId" },
            { label: "Device ID", key: "deviceId" },
            { label: "Interface Name", key: "interfaceName" },
            { label: "Unit ID", key: "unitId" },
            { label: "Unit Name", key: "unitName" },
            { label: "Point ID", key: "pointId" },
            { label: "Point Name", key: "pointName" },
            { label: "Input/Output Type", key: "type" },
            { label: "Initial Value", key: "initialValue" },
            { label: "Initial Timestamp", key: "initialTimestamp" },
            { label: "Test Value", key: "testValue" },
            { label: "Test Timestamp", key: "testTimestamp" },
            { label: "Final Value", key: "finalValue" },
            { label: "Final Timestamp", key: "finalTimestamp" },
            { label: "Test Started", key: "started" },
            { label: "Tested", key: "tested" },
            { label: "Pass/Fail", key: "testResult" },
            { label: "Test Ended", key: "ended" },
        ];
        setExportHeader(header);

        let currentDate = new Date();
        let filename = selectedUnit.replace("\\", "_") + "_" +
            currentDate.getFullYear() + ("0" + (currentDate.getMonth() + 1)).slice(-2) +
            ("0" + currentDate.getDate()).slice(-2) + "_Report.csv";

        setExportFilename(filename);

        setLoading(false);
    };

    return (
        <Box>
            {/* LOADING SPINNER */}
            {loading ? <Box
                sx={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    transform: "translate(0, 0)",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fffffff5",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    ml: "0",
                    zIndex: 3,
                    // borderRadius: "20px",
                }}
            >
                <CircularProgress
                    color="info"
                    size={70}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 45px)",
                        left: "calc(50% - 45px)",
                    }}
                />
                <Typography
                    id="progress description"
                    variant="h4"
                    color={colors.blueAccent[500]}
                    fontWeight="normal"
                    sx={{
                        position: "absolute",
                        top: "calc(75% - 25px)",
                        left: "calc(50% - 45px)",
                    }}
                >
                    Loading
                </Typography>
            </Box> : null}
            {/* HEADER */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="20px"
            >
                <Header
                    title="Export CSV"
                    subtitle=""
                />
            </Box>
            {/* BODY */}
            <Box
                p="20px"
            >
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {(props) => {
                        return (
                            <Form>
                                <Autocomplete
                                    id="unitId"
                                    fullWidth
                                    options={unitData?.map((data) => data.unitId) ?? []}
                                    getOptionLabel={(option) => {
                                        let data = unitData.find((data) => data.unitId === option);
                                        return (
                                            `${data?.interfaceName ?? ""} - ${data?.unitName ?? ""}`
                                        );
                                    }}
                                    value={props.values.unitId || null}
                                    onChange={(e, value) => props.setFieldValue("unitId", value)}
                                    onBlur={props.handleBlur}
                                    isOptionEqualToValue={(option, value) =>
                                        option?.unitId === value?.unitId
                                    }
                                    renderInput={(params) => (
                                        <Field
                                            as={TextField}
                                            {...params}
                                            label="Unit"
                                            name="unitId"
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            helperText={<ErrorMessage name="unitId" />}
                                            error={props.errors.unitId && props.touched.unitId}
                                            required
                                        />
                                    )}
                                />

                                {/*<Field*/}
                                {/*    as="select"*/}
                                {/*    label="Unit"*/}
                                {/*    name="unitId"*/}
                                {/*    fullwidth="true"*/}
                                {/*    variant="outlined"*/}
                                {/*    margin="dense"*/}
                                {/*    value={props.values.unitId}*/}
                                {/*    onChange={props.handleChange}*/}
                                {/*    onBlur={props.handleBlur}*/}
                                {/*    helpertext={<ErrorMessage name="unitId" />}*/}
                                {/*    error={`${props.errors.unitId && props.touched.unitId}`}*/}
                                {/*    required*/}
                                {/*>*/}
                                {/*    <option key="" value="" label="Select a unit">*/}
                                {/*        Select a unit{" "}*/}
                                {/*    </option>*/}
                                {/*    {unitData.map((data, i) => (*/}
                                {/*        <option key={`${data.unitId}`} value={`${data.unitId}`} label={`${data.interfaceName} - ${data.unitName}`}>*/}
                                {/*            {`${data.interfaceName} - ${data.unitName}`}*/}
                                {/*        </option>*/}
                                {/*    ))}*/}
                                {/*</Field>*/}

                                <Button
                                    // variant="contained"
                                    type="submit"
                                    // color="primary"
                                    sx={{
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                        textTransform: "uppercase",
                                        float: "right",
                                        ":hover": {
                                            backgroundColor: colors.grey[100],
                                            color: colors.blueAccent[700],
                                        },
                                    }}
                                >
                                    Export
                                    <DownloadOutlinedIcon
                                        sx={{
                                            ml: "10px"
                                        }}
                                    />
                                </Button>

                                <CSVLink
                                    data={exportData}
                                    headers={exportHeader}
                                    filename={exportFilename}
                                    className='hidden'
                                    ref={csvLink}
                                    target='_blank'
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};

export default CSV;

import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import{ ImageAssets } from '../assets/ImageAssets';
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:768px)");

    return (
        <MenuItem
            component={<Link to={to} />}
            active={selected === title}
            style={{
                margin: isNonMobile ? "10px 0 10px 20px" : "10px 0 10px 10px",
                color: colors.grey[100],
                backgroundColor: colors.grey[0],
                borderRadius: "12px 0 0 12px",
            }}
            onClick={() => setSelected(title)}
            icon={icon}
        >
            <Typography
                sx={{
                    fontSize: ".9rem",
                }}
            >
                {title}
            </Typography>
        </MenuItem>
    );
};

const MySidebar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:768px)");
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState("Dashboard");

    return (
        <Box
            sx={{
                display: "flex",
                height: "100%",
                top: 0,
                bottom: 0,
                "& .ps-sidebar-root": {
                    border: "none",
                },
                "& .ps-sidebar-container": {
                    background: "url(" + ImageAssets.sidebarBG + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "50% 50%",
                },
                "& .ps-menu-icon": {
                    backgroundColor: "transparent !important",
                },
                "& .ps-menu-icon img": {
                    width: "25px",
                },
                "& .ps-menu-button": {
                    padding: isNonMobile ? "5px 35px 5px 20px !important" : "5px !important",
                },
                "& .ps-menu-button:hover": {
                    color: "#6870fa !important",
                },
                "& .ps-menu-button:hover .ps-menu-icon img": {
                    width: "29px",
                    height: "29px",
                },
                "& .ps-menu-button:hover .MuiSvgIcon-root": {
                    width: "1.2em",
                    height: "1.2em",
                },
                "& .ps-menu-button.ps-active": {
                    color: "#6870fa !important",
                },
            }}
        >
            <Sidebar collapsed={isCollapsed} width={isNonMobile ? "195px" : "140px"} collapsedWidth={isNonMobile ? "80px" : "60px"}>
                <Menu iconShape="square">
                    {/*LOGO AND MENU ICON*/}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: isNonMobile ? "10px 0 20px 20px" : "10px 0 10px 10px",
                            color: colors.grey[100],
                            backgroundColor: colors.grey[0],
                            borderRadius: "12px 0 0 12px",
                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <IconButton
                                    onClick={() => setIsCollapsed(!isCollapsed)}
                                    sx={{
                                        color: colors.grey[100],
                                    }}
                                >
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box paddingLeft={isCollapsed ? undefined : "0"} paddingTop="100px">
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="CSV"
                            to="/CSV"
                            icon={<img src={ImageAssets.csvIcon}/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        <Item
                            title="Settings"
                            to="/Settings"
                            icon={<img src={ImageAssets.settingIcon}/>}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    );
};

export default MySidebar;

import { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { getDigitalOutputPollData, getPollData } from "../utils/helper";
import { tokens } from "../theme";

const DigitalOutputTest = ({ handleCompletion, unit, channelId, pointId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false);
    const [sendValue, setSendValue] = useState(-1);
    const [result, setResult] = useState(-1);
    const [serverValue, setServerValue] = useState('');

    useEffect(() => {
        if (sendValue > -1) {
            setLoading(true);
            getDigitalOutputPollData(channelId, pointId, sendValue).then(async (result) => {
                if (result) {
                    let queryData = [{
                        "Channel": channelId,
                        "Point": pointId,
                        "Type": -1,
                        "TestResult": ""
                    }];

                    let newPointData = {
                        "value": "",
                        "datetime": ""
                    };
                    let count = 0;
                    while ((newPointData.value === "" || newPointData.value === "Pending") && count <= 5) {
                        await getPollData(queryData).then(pollResult => {
                            let curValue = ((!pollResult || pollResult.result !== "OK") ? "Error" : pollResult.Data[0]);
                            let curDateTime = ((!!pollResult.devTimeRaw) ? new Date(parseInt(pollResult.devTimeRaw) * 1000) : new Date());
                            newPointData = {
                                "value": curValue,
                                "datetime": curDateTime
                            };
                            count += 1;
                        });
                    }

                    if (newPointData.value !== "" && newPointData.value !== "Pending" && newPointData.value !== "Error") {
                        setServerValue(newPointData.value);
                        setResult(1);
                    } else {
                        setServerValue('');
                        setResult(1);
                    }
                } else {
                    setResult(0);
                }
                setLoading(false);
            });
            setSendValue(-1);
        }
    }, [ sendValue, channelId ]);

    const handlePopupOn = () => {
        setSendValue(1);
    }
    const handlePopupOff = () => {
        setSendValue(0);
    }
    const handlePopupPass = () => {
        setResult(-1);
        setSendValue(-1);
        handleCompletion(channelId, pointId, 1);
    }
    const handlePopupFail = () => {
        setResult(-1);
        setSendValue(-1);
        handleCompletion(channelId, pointId, 0);
    }

    return (
        <Box m="20px">
            {/* LOADING SPINNER */}
            {loading ? <Box
                sx={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    transform: "translate(0, 0)",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fffffff5",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    ml: "0",
                    zIndex: 3,
                    borderRadius: "20px",
                }}
            >
                <CircularProgress
                    color="info"
                    size={70}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 45px)",
                        left: "calc(50% - 45px)",
                    }}
                />
                <Typography
                    id="progress description"
                    variant="h4"
                    color={colors.blueAccent[500]}
                    fontWeight="normal"
                    sx={{
                        position: "absolute",
                        top: "calc(75% - 25px)",
                        left: "calc(50% - 45px)",
                    }}
                >
                    Sending
                </Typography>
            </Box> : null}
            {result <= 0 && (
                <Box>
                    <Typography
                        id="title"
                        variant="h1"
                        color={colors.grey[100]}
                        fontWeight="normal"
                        sx={{ m: "0 0 5px 0" }}
                    >
                        SELECT COMMAND
                    </Typography>
                    <Typography
                        id="description"
                        variant="h5"
                        color={colors.greenAccent[400]}
                    >
                        Select command to send.
                    </Typography>
                    {result == 0 && (
                        <Typography
                            id="error"
                            variant="h5"
                            color={colors.redAccent[400]}
                        >
                            Failed to send the command. Please try again.
                        </Typography>
                    )}
                </Box>
            )}

            {result == 1 && (
                <Box>
                    <Typography
                        id="title"
                        variant="h1"
                        color={colors.grey[100]}
                        fontWeight="normal"
                        sx={{ m: "0 0 5px 0" }}
                    >
                        PASS / FAIL
                    </Typography>
                    <Typography
                        id="description"
                        variant="h5"
                        color={colors.greenAccent[400]}
                    >
                        Select the outcome of the test.
                    </Typography>
                    {serverValue !== '' && (
                        <Typography
                            id="error"
                            variant="h5"
                            color={colors.blueAccent[400]}
                        >
                            Command sent. Value from server is: {serverValue}.
                        </Typography>
                    )}
                    {serverValue === '' && (
                        <Typography
                            id="error"
                            variant="h5"
                            color={colors.blueAccent[400]}
                        >
                            Command sent but unable to get value from server.
                        </Typography>
                    )}
                </Box>
            )}

            {result <= 0 && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Button
                        onClick={handlePopupOn}
                        sx={{
                            backgroundColor: colors.greenAccent[400],
                            color: colors.grey[900],
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[900],
                                color: colors.greenAccent[400],
                            },
                        }}
                    >
                        ON
                    </Button>
                    <Button
                        onClick={handlePopupOff}
                        sx={{
                            backgroundColor: colors.redAccent[400],
                            color: colors.grey[900],
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[900],
                                color: colors.redAccent[400],
                            },
                        }}
                    >
                        OFF
                    </Button>
                </Box>
            )}

            {result == 1 && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Button
                        onClick={handlePopupPass}
                        sx={{
                            backgroundColor: colors.greenAccent[400],
                            color: colors.grey[900],
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[900],
                                color: colors.greenAccent[400],
                            },
                        }}
                    >
                        PASS
                    </Button>
                    <Button
                        onClick={handlePopupFail}
                        sx={{
                            backgroundColor: colors.redAccent[400],
                            color: colors.grey[900],
                            fontSize: "16px",
                            fontWeight: "600",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[900],
                                color: colors.redAccent[400],
                            },
                        }}
                    >
                        FAIL
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default DigitalOutputTest;
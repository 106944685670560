import {Typography, Box, useTheme, IconButton} from "@mui/material";
import { tokens } from "../theme";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Header = ({ title, subtitle }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            mt="15px"
            mb="15px"
            flex="1"
        >
            {/* TITLE & SUB TITLE */}
            <Box
                display="flex"
            >
                <Typography
                    variant="h1"
                    color={colors.grey[1000]}
                    fontWeight="normal"
                    sx={{
                        m: "0 0 5px 0"
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h5"
                    color={colors.grey[1000]}
                >
                    {subtitle}
                </Typography>
            </Box>
            {/* SEARCH BAR */}
            {/*<Box*/}
            {/*    display="flex"*/}
            {/*    backgroundColor={colors.primary[400]}*/}
            {/*    borderRadius="25px"*/}
            {/*>*/}
            {/*    <InputBase*/}
            {/*        sx={{*/}
            {/*            ml: 2,*/}
            {/*            flex: 1,*/}
            {/*        }}*/}
            {/*        placeholder="Search"*/}
            {/*    />*/}
            {/*    <IconButton*/}
            {/*        type="button"*/}
            {/*        sx={{*/}
            {/*            p: 1,*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <SearchIcon />*/}
            {/*    </IconButton>*/}
            {/*</Box>*/}
        </Box>
    );
};

export default Header;

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Box, Button, Typography, Divider, useTheme, CircularProgress, Grid } from "@mui/material";
import { tokens } from "../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import { getSetting, getDataById, getTypeDisplay, calculateProgress } from "../utils/helper";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import StatBox from "../components/StatBox";
import AddPoint from "../components/AddPoint";
import RemovePoint from "../components/RemovePoint";
import SelectData from "../components/SelectData";

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:768px)");
    const [unitData, setUnitData] = useState([]);
    const [unitDataLocal, setUnitDataLocal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({ show: false, modalId: -1, unitId: -1, pointId: -1 });
    const [currentModal, setCurrentModal] = useState(null);
    const [currentServerChannelId, setCurrentServerChannelId] = useState(4);
    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        setLoading(true);
        getSetting().then(settingData => {
            console.log("Dashboard settingData: " + JSON.stringify(settingData));

            getDataById('', 'local').then(localData => {
                console.log("Dashboard unitData Local: " + JSON.stringify(localData));

                setUnitDataLocal(localData);

                getDataById('', 'server').then(serverData => {
                    console.log("Dashboard unitData Server: " + JSON.stringify(serverData));

                    setUnitData(serverData);
                    setLoading(false);
                });
            });
        });
    }, [ currentServerChannelId ]);

    useEffect(() => {
        if (refreshData) {
            setLoading(true);
            getSetting().then(settingData => {
                console.log("Dashboard refresh settingData: " + JSON.stringify(settingData));

                getDataById('', 'local', true).then(localData => {
                    console.log("Dashboard refresh unitData Local: " + JSON.stringify(localData));

                    setUnitDataLocal(localData);

                    getDataById('', 'server', true).then(serverData => {
                        console.log("Dashboard refresh unitData Server: " + JSON.stringify(serverData));

                        setUnitData(serverData);
                        setRefreshData(false);
                        setLoading(false);
                    });
                });
            });
        }
    }, [ refreshData ]);

    const handleChannelChange = (channelId) => {
        handleModalClose();
        setCurrentServerChannelId(channelId);
    };
    const handleModalOpen = (modalId = -1, unitId = -1, pointId = -1) => {
        // Set Modal Identifier
        setModal({ show: true, modalId: modalId, unitId: unitId, pointId: pointId });
        // Set Modal Content
        if (modalId === 0 && unitId >= 0) {
            setCurrentModal(<AddPoint unit={unitId} />);
        } else if (modalId === 1 && unitId >= 0 && pointId >= 0) {
            setCurrentModal(<RemovePoint unit={unitId} point={pointId} />);
        } else if (modalId === 2) {
            setCurrentModal(<SelectData handleChannelChange={handleChannelChange} />);
        }
    };
    const handleModalClose = () => setModal({ show: false, modalId: -1, unitId: -1, pointId: -1 });

    return (
        <Box>
            {/* LOADING SPINNER */}
            {loading ? <Box
                sx={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    transform: "translate(0, 0)",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fffffff5",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    ml: "0",
                    zIndex: 3,
                    // borderRadius: "20px",
                }}
            >
                <CircularProgress
                    color="info"
                    size={70}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 45px)",
                        left: "calc(50% - 45px)",
                    }}
                />
                <Typography
                    id="progress description"
                    variant="h4"
                    color={colors.blueAccent[500]}
                    fontWeight="normal"
                    sx={{
                        position: "absolute",
                        top: "calc(75% - 25px)",
                        left: "calc(50% - 45px)",
                    }}
                >
                    Loading
                </Typography>
            </Box> : null}
            {/* MODAL */}
            <Modal
                open={modal.show}
                onClose={handleModalClose}
                aria-labelledby="title"
                aria-describedby="description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 490,
                        backgroundColor: colors.primary[400],
                        border: `2px solid ${colors.primary[600]}`,
                        borderRadius: 5,
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 2,
                    }}
                >
                    {currentModal}
                </Box>
            </Modal>
            {/* HEADER */}
            <Box
                display={isNonMobile ? "flex" : "block"}
                justifyContent="space-between"
                alignItems="center"
                px="20px"
            >
                <Header
                    title="Unit Report"
                    subtitle=""
                />

                <Box
                    display="flex"
                    justifyContent="flex-end"
                    mt="15px"
                    mb="15px"
                    flex={isNonMobile ? "0.2" : "1"}
                >
                    <Button
                        onClick={() => handleModalOpen(2, -1, -1)}
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "10px",
                            fontWeight: "800",
                            padding: "10px 10px",
                            margin: "0 10px 0 10px",
                            flex: 1,
                        }}
                    >
                        <DownloadOutlinedIcon
                            sx={{
                                mr: "7px"
                            }}
                        />
                        Get New Data
                    </Button>
                </Box>

                <Box
                    display="flex"
                    justifyContent="flex-end"
                    mt="15px"
                    mb="15px"
                    flex={isNonMobile ? "0.2" : "1"}
                >
                    <Button
                        onClick={() => setRefreshData(true)}
                        sx={{
                            backgroundColor: colors.grey[700],
                            color: colors.grey[100],
                            fontSize: "10px",
                            fontWeight: "800",
                            padding: "10px 10px",
                            margin: "0 10px 0 10px",
                            flex: 1,
                        }}
                    >
                        <CachedOutlinedIcon
                            sx={{
                                mr: "7px"
                            }}
                        />
                        Refresh Data
                    </Button>
                </Box>
            </Box>
            {/* BODY */}
            {/** LOCAL DATA **/}
            <Divider
                textAlign="left"
                sx={{
                    color: colors.greenAccent[400],
                }}
            >
                Local Data
            </Divider>
            <Grid
                container
                spacing={2}
                maxWidth="96%"
                mx={isNonMobile ? "20px" : "0"}
                mt="0"
                mb="20px"
            >
                {unitDataLocal.map((data, i) => (
                    <Grid
                        key={`local-unit-${data.unitId}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                    >
                        <Box
                            overflow="hidden"
                            borderRadius="5px"
                            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                backgroundColor={colors.primary[400]}
                                p="15px"
                            >
                                <StatBox
                                    title={`${data.interfaceName}`}
                                    subtitle={`${data.unitName}`}
                                    progress={`${calculateProgress(data)}`}
                                />
                            </Box>
                            <Box
                                height="135px"
                                overflow="auto"
                            >
                                {data.points.map((subData, j) => (
                                    <Box
                                        key={`local-point-${subData.pointId}`}
                                        display="flex"
                                        flex="1 0 100%"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        p="5px 15px"
                                    >
                                        <Box>
                                            <Typography
                                                color={colors.grey[100]}
                                                variant="h5"
                                                sx={{
                                                    fontSize: ".9rem",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {subData.pointName}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            {/*<Button*/}
                                            {/*    key={`${subData.pointId}`}*/}
                                            {/*    onClick={() => handleModalOpen(1, data.deviceId, subData.pointId)}*/}
                                            {/*    sx={{*/}
                                            {/*        // backgroundColor: colors.blueAccent[700],*/}
                                            {/*        color: colors.redAccent[500],*/}
                                            {/*        fontSize: "16px",*/}
                                            {/*        fontWeight: "bold",*/}
                                            {/*        padding: "0",*/}
                                            {/*        minWidth: "fit-content",*/}
                                            {/*        ":hover": {*/}
                                            {/*            backgroundColor: colors.redAccent[500],*/}
                                            {/*            color: colors.grey[100],*/}
                                            {/*        },*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <ClearOutlinedIcon />*/}
                                            {/*</Button>*/}
                                            <Typography
                                                color={colors.grey[100]}
                                                variant="h5"
                                                sx={{
                                                    fontSize: ".9rem",
                                                    textTransform: 'none',
                                                }}
                                            >
                                                {getTypeDisplay(subData.type, "short")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                borderBottom="none"
                                p="0 15px"
                            >
                                {/*<Button*/}
                                {/*    onClick={() => handleModalOpen(0, data.deviceId, -1)}*/}
                                {/*    sx={{*/}
                                {/*        // backgroundColor: colors.blueAccent[700],*/}
                                {/*        color: colors.greenAccent[500],*/}
                                {/*        fontSize: "14px",*/}
                                {/*        fontWeight: "normal",*/}
                                {/*        padding: "0",*/}
                                {/*        textTransform: "none",*/}
                                {/*        ":hover": {*/}
                                {/*            backgroundColor: colors.greenAccent[700],*/}
                                {/*            color: colors.grey[100],*/}
                                {/*        },*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    Add Point*/}
                                {/*</Button>*/}
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderTop={`1px solid #bfbfbf`}
                                p="20px 15px"
                                m="10px 20px 0 20px"
                            >
                                <Button
                                    component={Link}
                                    to={`/UnitTesting`}
                                    state={{ unit: data.unitId }}
                                    sx={{
                                        backgroundColor: "#212883",
                                        color: colors.grey[0],
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        padding: "5px 15px",
                                        textTransform: "none",
                                        ":hover": {
                                            backgroundColor: colors.grey[0],
                                            color: "#212883",
                                        },
                                        ".disabled": {
                                            color: colors.grey[300],
                                        },
                                    }}
                                >
                                    Continue Testing
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            {/** SERVER DATA **/}
            <Divider
                textAlign="left"
                sx={{
                    color: colors.greenAccent[400],
                }}
            >
                Server Data
            </Divider>
            <Grid
                container
                spacing={2}
                maxWidth="96%"
                mx={isNonMobile ? "20px" : "0"}
                mt="0"
                mb="20px"
            >
                {unitData.map((data, i) => (
                    <Grid
                        key={`server-unit-${data.unitId}`}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                    >
                        <Box
                            overflow="hidden"
                            borderRadius="5px"
                            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                backgroundColor={colors.primary[400]}
                                p="15px"
                            >
                                <StatBox
                                    title={`${data.interfaceName}`}
                                    subtitle={`${data.unitName}`}
                                    progress={false}
                                />
                            </Box>
                            <Box
                                height="135px"
                                overflow="auto"
                            >
                                {data.points.map((subData, j) => (
                                    <Box
                                        key={`server-point-${subData.pointId}`}
                                        display="flex"
                                        flex="1 0 100%"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        p="5px 15px"
                                    >
                                        <Box>
                                            <Typography
                                                color={colors.grey[100]}
                                                variant="h5"
                                                sx={{
                                                    fontSize: ".9rem",
                                                    textTransform: "uppercase",
                                                }}
                                            >
                                                {subData.pointName}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            {/*<Button*/}
                                            {/*    key={`${subData.pointId}`}*/}
                                            {/*    onClick={() => handleModalOpen(1, data.deviceId, subData.pointId)}*/}
                                            {/*    sx={{*/}
                                            {/*        // backgroundColor: colors.blueAccent[700],*/}
                                            {/*        color: colors.redAccent[500],*/}
                                            {/*        fontSize: "16px",*/}
                                            {/*        fontWeight: "bold",*/}
                                            {/*        padding: "0",*/}
                                            {/*        minWidth: "fit-content",*/}
                                            {/*        ":hover": {*/}
                                            {/*            backgroundColor: colors.redAccent[500],*/}
                                            {/*            color: colors.grey[100],*/}
                                            {/*        },*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <ClearOutlinedIcon />*/}
                                            {/*</Button>*/}
                                            <Typography
                                                color={colors.grey[100]}
                                                variant="h5"
                                                sx={{
                                                    fontSize: ".9rem",
                                                    textTransform: 'none',
                                                }}
                                            >
                                                {getTypeDisplay(subData.type, "short")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                borderBottom="none"
                                p="0 15px"
                            >
                                {/*<Button*/}
                                {/*    onClick={() => handleModalOpen(0, data.deviceId, -1)}*/}
                                {/*    sx={{*/}
                                {/*        // backgroundColor: colors.blueAccent[700],*/}
                                {/*        color: colors.greenAccent[500],*/}
                                {/*        fontSize: "14px",*/}
                                {/*        fontWeight: "normal",*/}
                                {/*        padding: "0",*/}
                                {/*        textTransform: "none",*/}
                                {/*        ":hover": {*/}
                                {/*            backgroundColor: colors.greenAccent[700],*/}
                                {/*            color: colors.grey[100],*/}
                                {/*        },*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    Add Point*/}
                                {/*</Button>*/}
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                p="20px 15px"
                            >
                                <Button
                                    component={Link}
                                    to={`/UnitTesting`}
                                    state={{ unit: data.unitId }}
                                    sx={{
                                        backgroundColor: "#212883",
                                        color: colors.grey[0],
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        padding: "5px 15px",
                                        textTransform: "none",
                                        ":hover": {
                                            backgroundColor: colors.grey[0],
                                            color: "#212883",
                                        },
                                        ".disabled": {
                                            color: colors.grey[300],
                                        },
                                    }}
                                >
                                    Start Testing
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Dashboard;

import { useState, useEffect } from "react";
import { Box, Typography, useTheme, CircularProgress, Grid } from "@mui/material";
import { tokens } from "../theme";
import { getSetting } from "../utils/helper";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import SelectData from "../components/SelectData";
import FailureRange from "../components/FailureRange";

const Settings = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:768px)");
    const [loading, setLoading] = useState(true);
    const [currentFailurePercent, setCurrentFailurePercent] = useState('');
    const [currentServerChannelId, setCurrentServerChannelId] = useState('');

    useEffect(() => {
        setLoading(true);
        getSetting().then(settingData => {
            console.log("Setting settingData: " + JSON.stringify(settingData));
            setLoading(false);
        });
    }, [ currentFailurePercent, currentServerChannelId ]);

    const handleFailureRangeChange = (failureRange) => {
        console.log("Settings Failure Range: ", failureRange);

        let previousFailurePercent = sessionStorage.getItem("previousFailurePercent");
        let currentFailurePercent = sessionStorage.getItem("currentFailurePercent");

        if (previousFailurePercent != currentFailurePercent) {
            setCurrentFailurePercent(currentFailurePercent);

            alert("Data updated.");
        } else {
            alert("No Data changed.");
        }
    };
    const handleChannelChange = (channelId) => {
        console.log("Settings Channel Id: ", channelId);

        let previousServerChannelId = sessionStorage.getItem("previousServerChannelId");
        let currentServerChannelId = sessionStorage.getItem("currentServerChannelId");

        if (previousServerChannelId != currentServerChannelId) {
            setCurrentServerChannelId(currentServerChannelId);

            alert("Data updated.");
        } else {
            alert("No data changed.");
        }
    };

    return (
        <Box>
            {/* LOADING SPINNER */}
            {loading ? <Box
                sx={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    transform: "translate(0, 0)",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fffffff5",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    ml: "0",
                    zIndex: 3,
                    // borderRadius: "20px",
                }}
            >
                <CircularProgress
                    color="info"
                    size={70}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 45px)",
                        left: "calc(50% - 45px)",
                    }}
                />
                <Typography
                    id="progress description"
                    variant="h4"
                    color={colors.blueAccent[500]}
                    fontWeight="normal"
                    sx={{
                        position: "absolute",
                        top: "calc(75% - 25px)",
                        left: "calc(50% - 45px)",
                    }}
                >
                    Loading
                </Typography>
            </Box> : null}
            {/* HEADER */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="20px"
            >
                <Header
                    title="Settings"
                    subtitle=""
                />
            </Box>
            <Grid
                container
                spacing={2}
                maxWidth="96%"
                mx={isNonMobile ? "20px" : "0"}
                mt="0"
                mb="20px"
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <Box
                        overflow="auto"
                        borderRadius="5px"
                        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    >
                        <FailureRange handleFailureRangeChange={handleFailureRangeChange} />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <Box
                        overflow="auto"
                        borderRadius="5px"
                        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    >
                        <SelectData handleChannelChange={handleChannelChange} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Settings;

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Modal, Box, Button, Typography, useTheme, CircularProgress } from "@mui/material";
import { DataGrid, GridFooterContainer, useGridApiRef } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { getSetting, getDataById, formatDate, setDataById, getTypeDisplay, getValueDisplay, getPollData, sleep } from "../utils/helper";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import DigitalOutputTest from "../components/DigitalOutputTest";
import AnalogOutputTest from "../components/AnalogOutputTest";

const UnitTesting = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:768px)");
    const apiRef = useGridApiRef();
    const location = useLocation();
    const { unit } = location.state ?? {};
    const [unitData, setUnitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [start, setStart] = useState(false);
    const [end, setEnd] = useState(false);
    const [startedCount, setStartedCount] = useState(0);
    const [endedCount, setEndedCount] = useState(0);
    const [testedCount, setTestedCount] = useState(0);
    const [totalPoint, setTotalPoint] = useState(0);
    const [modal, setModal] = useState({ show: false, modalId: -1, unitId: '', pointId: '', channelId: -1 });
    const [currentModal, setCurrentModal] = useState(null);
    const [isPrint, setIsPrint] = useState(false);

    useEffect(() => {
        setLoading(true);
        getSetting().then(settingData => {
            console.log("UnitTesting settingData: " + JSON.stringify(settingData));

            getDataById(unit).then(result => {
                setUnitData(result);
                setStart(result[0]?.start);
                setEnd(result[0]?.end);
                setStartedCount((!!result[0]?.points?.filter(data => data.started === true).length) ? result[0]?.points?.filter(data => data.started === true).length : 0);
                setEndedCount((!!result[0]?.points?.filter(data => data.ended === true).length) ? result[0]?.points?.filter(data => data.ended === true).length : 0);
                setTestedCount((!!result[0]?.points?.filter(data => data.tested === true).length) ? result[0]?.points?.filter(data => data.tested === true).length : 0);
                setTotalPoint((!!result[0]?.points?.length) ? result[0]?.points?.length : 0);
                setLoading(false);

                console.log("UnitTesting unitData: " + JSON.stringify(result));
                console.log("UnitTesting Start: " + result[0]?.start);
                console.log("UnitTesting End: " + result[0]?.end);
                console.log("UnitTesting Started Count: " + (!!result[0]?.points?.filter(data => data.started === true).length) ? result[0]?.points?.filter(data => data.started === true).length : 0);
                console.log("UnitTesting Ended Count: " + (!!result[0]?.points?.filter(data => data.ended === true).length) ? result[0]?.points?.filter(data => data.ended === true).length : 0);
                console.log("UnitTesting Tested Count: " + (!!result[0]?.points?.filter(data => data.tested === true).length) ? result[0]?.points?.filter(data => data.tested === true).length : 0);
                console.log("UnitTesting Total Point: " + (!!result[0]?.points?.length) ? result[0]?.points?.length : 0);
            });
        });
    }, [ unit ]);

    useEffect(() => {
        if (start && startedCount === totalPoint) {
            unitData[0].start = start;

            console.log("UnitTesting start complete");
        }
    }, [ start, startedCount, totalPoint, unitData ]);

    useEffect(() => {
        if (end && endedCount === totalPoint) {
            unitData[0].end = end;

            console.log("UnitTesting end complete");
        }
    }, [ end, endedCount, totalPoint, unitData ]);

    useEffect(() => {
        //if (loading === false) {
            setDataById(unit, unitData);

            console.log("UnitTesting unitData updated");
        //}
    }, [ unit, unitData, loading ]);

    useEffect(() => {
        if (isPrint) {
            sleep(1000).then(() => {
                window.print();
                setIsPrint(false);
            });
        }
    }, [ isPrint ]);

    const handlePopupCompletion = (channelId, pointId, result) => {
        let resultDisplay = ((result === 0) ? 'Fail' : ((result === 1) ? 'Pass' : ''));

        handleUpdateRow([{
            "Channel": channelId,
            "Point": pointId,
            "Type": -1,
            "TestResult": resultDisplay
        }], "test");

        handleModalClose(false);
    };
    const handleModalOpen = (modalId = -1, unitId = '', pointId = '', channelId = -1) => {
        // Set Modal identifier
        setModal({ show: true, modalId: modalId, unitId: unitId, pointId: pointId, channelId: channelId });
        // Set Modal content
        if (modalId === 0 && unitId !== '' && pointId !== '' && channelId >= 0) {
            setCurrentModal(<DigitalOutputTest handleCompletion={handlePopupCompletion} unit={unitId} channelId={channelId} pointId={pointId} />);
        } else if (modalId === 1 && unitId !== '' && pointId !== '') {
            setCurrentModal(<AnalogOutputTest handleCompletion={handlePopupCompletion} unit={unitId} channelId={channelId} pointId={pointId} />);
        }
    };
    const handleModalClose = (turnOffLoading = true) => {
        // Reset Modal identifier
        setModal({ show: false, modalId: -1, unitId: '', pointId: '', channelId: -1 });
        // Hide Loading element
        if (turnOffLoading) {
            setLoading(false);
        }
    }
    const handleUpdateRow = (queryData = [{"Channel": -1,"Point": "","Type": -1, "TestResult": ""}], phase) => {
        getPollData(queryData).then(result => {
            console.log("UnitTesting getPollData Result: " + JSON.stringify(result));

            let hasPending = false;

            for (let i = 0; i < queryData.length; i++) {
                // Get new data
                let curValue = ((!result || result.result !== "OK") ? "Error" : result.Data[i]);
                let curDateTime = ((!!result.devTimeRaw) ? new Date(parseInt(result.devTimeRaw) * 1000) : new Date());
                let newPointData = {
                    "value": curValue,
                    "datetime": curDateTime
                };

                let finalTestResult = '';
                if (!!queryData[i].TestResult) {
                    finalTestResult = queryData[i].TestResult;

                    if (newPointData.value === "Pending") {
                        hasPending = true;
                    }

                    if (newPointData.value === "Error") {
                        finalTestResult = 'Fail';
                    }
                } else if (newPointData.value === "Nan" || newPointData.value === "INF" || newPointData.value === "Unknown Label" ||
                    newPointData.value === "Unknown Channel" || newPointData.value === "Slave Offline" || newPointData.value === "Unknown Point ID" ||
                    newPointData.value === "Error") {

                    finalTestResult = 'Fail';
                } else if (newPointData.value === "Pending") {
                    finalTestResult = "Pending";
                    hasPending = true;
                } else {
                    finalTestResult = "Pass";
                }

                let pointIndex = unitData[0]?.points.findIndex(data => data.pointId === queryData[i].Point);
                if (pointIndex >= 0 && finalTestResult !== "Pending") {
                    if (phase === "initial") {

                        // Update Data Grid
                        apiRef.current.updateRows([{
                            pointId: queryData[i].Point,
                            initialValue: newPointData.value,
                            initialTimestamp: newPointData.datetime,
                            testValue: "",
                            testTimestamp: "",
                            finalValue: "",
                            finalTimestamp: "",
                            // testResult: finalTestResult,
                        }]);

                        // Update unitData
                        unitData[0].points[pointIndex].started = (finalTestResult === "Pass");
                        unitData[0].points[pointIndex].initialValue = newPointData.value;
                        unitData[0].points[pointIndex].initialTimestamp = newPointData.datetime;
                        unitData[0].points[pointIndex].tested = false;
                        unitData[0].points[pointIndex].testValue = "";
                        unitData[0].points[pointIndex].testTimestamp = "";
                        unitData[0].points[pointIndex].ended = false;
                        unitData[0].points[pointIndex].finalValue = "";
                        unitData[0].points[pointIndex].finalTimestamp = "";
                        // unitData[0].points[pointIndex].testResult = "";

                        // Update startedCount state
                        setStartedCount(unitData[0]?.points?.filter(data => data.started === true).length);

                        // Update unitDate state
                        setUnitData(unitData);

                    } else if (phase === "test") {

                        if (queryData[i].Type === 0 && finalTestResult === "Pass") { // analog input
                            let failurePercentage = JSON.parse(sessionStorage.getItem("currentFailurePercent"));
                            if (failurePercentage == null) {
                                failurePercentage = 5;
                                sessionStorage.setItem("previousFailurePercent", failurePercentage);
                                sessionStorage.setItem("currentFailurePercent", failurePercentage);
                            }
                            let initialValue = parseFloat(unitData[0].points[pointIndex].initialValue);
                            let testValue = parseFloat(newPointData.value);
                            let diffValue = Math.abs(testValue - initialValue);
                            let differentPercent = parseFloat((diffValue / initialValue) * 100);
                            if (differentPercent > parseFloat(failurePercentage)) {
                                finalTestResult = 'Pass';
                            } else {
                                finalTestResult = 'Fail';
                            }

                            console.log("UnitTesting test AI different percentage: " + differentPercent);
                            console.log("UnitTesting test AI failure percentage: " + failurePercentage);
                            console.log("UnitTesting test AI final test result: " + finalTestResult);

                        } else if ((queryData[i].Type === 1 || queryData[i].Type === 2 || queryData[i].Type === 3 || queryData[i].Type === 4 ||
                            queryData[i].Type === 5) && finalTestResult === "Pass" && unitData[0].points[pointIndex].initialValue == newPointData.value) {

                            finalTestResult = 'Fail';
                        }

                        // Update Data Grid
                        apiRef.current.updateRows([{
                            pointId: queryData[i].Point,
                            testValue: newPointData.value,
                            testTimestamp: newPointData.datetime,
                            testResult: finalTestResult,
                        }]);

                        // Update unitData
                        unitData[0].points[pointIndex].tested = true; //(finalTestResult === "Pass");
                        unitData[0].points[pointIndex].testValue = newPointData.value;
                        unitData[0].points[pointIndex].testTimestamp = newPointData.datetime;
                        unitData[0].points[pointIndex].testResult = finalTestResult;

                        // Update testedCount state
                        setTestedCount(unitData[0]?.points?.filter(data => data.tested === true).length);

                        // Update unitDate state
                        setUnitData(unitData);

                    } else if (phase === "final") {

                        // Update Data Grid
                        apiRef.current.updateRows([{
                            pointId: queryData[i].Point,
                            finalValue: newPointData.value,
                            finalTimestamp: newPointData.datetime,
                            // testResult: finalTestResult,
                        }]);

                        // Update unitData
                        unitData[0].points[pointIndex].ended = (finalTestResult === "Pass");
                        unitData[0].points[pointIndex].finalValue = newPointData.value;
                        unitData[0].points[pointIndex].finalTimestamp = newPointData.datetime;
                        // unitData[0].points[pointIndex].testResult = finalTestResult;

                        // Update endedCount state
                        setEndedCount(unitData[0]?.points?.filter(data => data.ended === true).length);

                        // Update unitDate state
                        setUnitData(unitData);

                    }
                }
            }

            if (hasPending) {
                console.log("UnitTesting test hasPending");

                handleUpdateRow(queryData, phase);
            } else {
                setLoading(false);
            }
        });
    };
    const columns = [
        { field: "id", headerName: "#", flex: 0.2,
            filterable: false,
            renderCell: (params) => (
                params.api.getRowIndexRelativeToVisibleRows(params.row.pointId) + 1
            )
        },
        { field: "pointId", headerName: "ID", flex: 1 },
        { field: "typeNumber", headerName: "Type Number", flex: 1 },
        { field: "channelNumber", headerName: "Channel Number", flex: 1 },
        { field: "pointName", headerName: "Points", flex: 1 },
        { field: "type", headerName: "Type", flex: 0.3 },
        { field: "initialValue", headerName: "Initial Value", flex: 1,
            renderCell: (params) => (
                <div>
                    <Typography>{(!!params?.value?.value) ? getValueDisplay(params?.row?.typeNumber, params?.value?.value): ""}</Typography>
                    <Typography>{(!!params?.value?.timestamp) ? formatDate(new Date(params?.value?.timestamp)) : ""}</Typography>
                </div>
            )
        },
        // { field: "initialTimestamp", headerName: "", flex: 1.2,
        //     type: "dateTime",
        //     valueGetter: (params) => ((!!params?.value) ? new Date(params?.value) : ""),
        //     valueFormatter: (params) => formatDate(params?.value)
        // },
        { field: "testValue", headerName: "Testing Value", flex: 1,
            renderCell: (params) => (
                <div>
                    <Typography>{(!!params?.value?.value) ? getValueDisplay(params?.row?.typeNumber, params?.value?.value) : ""}</Typography>
                    <Typography>{(!!params?.value?.timestamp) ? formatDate(new Date(params?.value?.timestamp)) : ""}</Typography>
                </div>
            )
        },
        // { field: "testTimestamp", headerName: "", flex: 1.2,
        //     type: "dateTime",
        //     valueGetter: (params) => ((!!params?.value) ? new Date(params?.value) : ""),
        //     valueFormatter: (params) => formatDate(params?.value)
        // },
        { field: "finalValue", headerName: "Final Value", flex: 1,
            renderCell: (params) => (
                <div>
                    <Typography>{(!!params?.value?.value) ? getValueDisplay(params?.row?.typeNumber, params?.value?.value) : ""}</Typography>
                    <Typography>{(!!params?.value?.timestamp) ? formatDate(new Date(params?.value?.timestamp)) : ""}</Typography>
                </div>
            )
        },
        // { field: "finalTimestamp", headerName: "", flex: 1.2,
        //     type: "dateTime",
        //     valueGetter: (params) => ((!!params?.value) ? new Date(params?.value) : ""),
        //     valueFormatter: (params) => formatDate(params?.value)
        // },
        { field: "testResult", headerName: "PASS / FAIL", flex: 0.3 },
        { field: "action", headerName: "", flex: isNonMobile ? 0.4 : 1,
            sortable: false,
            renderCell: (params) => {
                const onClickTest = (e) => {
                    e.stopPropagation();

                    setLoading(true);

                    // Update current row test data
                    const currentRow = params.row;
                    if (currentRow.typeNumber === 4 || currentRow.typeNumber === 5) {
                        handleModalOpen(0, unit, currentRow.pointId, currentRow.channelNumber);
                    } else if (currentRow.typeNumber === 1 || currentRow.typeNumber === 2) {
                        handleModalOpen(1, unit, currentRow.pointId, currentRow.channelNumber);
                    } else if (currentRow.typeNumber === 255) {
                        alert("Unable to proceed the test because the point's type is undefined.");

                        setLoading(false);
                    } else {
                        handleUpdateRow([{
                            "Channel": currentRow.channelNumber,
                            "Point": currentRow.pointId,
                            "Type": currentRow.typeNumber,
                            "TestResult": ""
                        }], "test");
                    }

                    return;
                };

                return (
                    <Button
                        disabled={false} // {!start || end}
                        onClick={onClickTest}
                        sx={{
                            backgroundColor: "#2B59CE",
                            color: colors.grey[0],
                            fontSize: "16px",
                            fontWeight: "400",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[0],
                                color: "#2B59CE",
                            },
                            ".disabled": {
                                color: colors.grey[300],
                            },
                        }}
                    >
                        Test
                    </Button>
                )
            }
        }
    ];
    const rows = unitData.flatMap((data, i) => {
        return data.points.map((subData, j) => {
            return {
                pointId: subData.pointId,
                typeNumber: subData.type,
                channelNumber: data.channelId,
                pointName: subData.pointName,
                type: getTypeDisplay(subData.type, "short"),
                initialValue: { "value": subData.initialValue, "timestamp": subData.initialTimestamp },
                // initialTimestamp: subData.initialTimestamp,
                testValue: { "value": subData.testValue, "timestamp": subData.testTimestamp },
                // testTimestamp: subData.testTimestamp,
                finalValue: { "value": subData.finalValue, "timestamp": subData.finalTimestamp },
                // finalTimestamp: subData.finalTimestamp,
                testResult: subData.testResult
            }
        })
    });
    const customFooter = () => {
        const onClickStart = (e) => {
            e.stopPropagation();

            setLoading(true);

            // Update all rows initial data
            const rowIds = apiRef.current.getAllRowIds();
            const combinedData = [];

            for (let i in rowIds) {
                const currentRow = apiRef.current.getRow(rowIds[i]);

                combinedData.push({
                    "Channel": currentRow.channelNumber,
                    "Point": currentRow.pointId,
                    "Type": currentRow.typeNumber,
                    "TestResult": ""
                });
            }

            handleUpdateRow(combinedData, "initial");

            // Update start state
            setStart(true);

            return;
        };

        const onClickEnd = (e) => {
            e.stopPropagation();

            setLoading(true);

            // Update all rows final data
            const rowIds = apiRef.current.getAllRowIds();
            const combinedData = [];

            for (let i in rowIds) {
                const currentRow = apiRef.current.getRow(rowIds[i]);

                combinedData.push({
                    "Channel": currentRow.channelNumber,
                    "Point": currentRow.pointId,
                    "Type": currentRow.typeNumber,
                    "TestResult": ""
                });
            }

            handleUpdateRow(combinedData, "final");

            // Update end state
            setEnd(true);

            return;
        };

        const onClickReset = (e) => {
            e.stopPropagation();

            if (window.confirm("Are you sure? This action cannot be undone!") === true) {
                setLoading(true);

                // reset all rows data
                for (let i = 0; i < unitData[0].points.length; i++) {
                    // Update Data Grid
                    apiRef.current.updateRows([{
                        pointId: unitData[0].points[i].pointId,
                        initialValue: "",
                        initialTimestamp: "",
                        testValue: "",
                        testTimestamp: "",
                        finalValue: "",
                        finalTimestamp: "",
                        testResult: "",
                    }]);

                    // Update unitData
                    unitData[0].points[i].started = false;
                    unitData[0].points[i].initialValue = "";
                    unitData[0].points[i].initialTimestamp = "";
                    unitData[0].points[i].tested = false;
                    unitData[0].points[i].testValue = "";
                    unitData[0].points[i].testTimestamp = "";
                    unitData[0].points[i].ended = false;
                    unitData[0].points[i].finalValue = "";
                    unitData[0].points[i].finalTimestamp = "";
                    unitData[0].points[i].testResult = "";
                }

                console.log(unitData[0]);

                // Update startedCount state
                setStartedCount(unitData[0]?.points?.filter(data => data.started === true).length);
                // Update testedCount state
                setTestedCount(unitData[0]?.points?.filter(data => data.tested === true).length);
                // Update endedCount state
                setEndedCount(unitData[0]?.points?.filter(data => data.ended === true).length);

                setUnitData(unitData);

                setDataById(unit, unitData);

                console.log("UnitTesting Reset unitData updated");

                setLoading(false);
            }

            return;
        };

        const onClickPrint = (e) => {
            e.stopPropagation();

            setIsPrint(true);

            return;
        };

        return (
            <GridFooterContainer
                sx={{
                    display: isNonMobile ? "flex" : "block",
                }}
            >
                <Box
                    m="20px 0 0 0"
                >
                    <Button
                        disabled={false} // {testedCount === totalPoint || start}
                        onClick={onClickStart}
                        sx={{
                            backgroundColor: "#016A18",
                            color: colors.grey[0],
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "0 20px 20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[0],
                                color: "#016A18",
                            },
                            ".disabled": {
                                color: colors.grey[300],
                            },
                        }}
                    >
                        Start
                    </Button>
                    <Button
                        disabled={false} // {testedCount !== totalPoint || end}
                        onClick={onClickEnd}
                        sx={{
                            backgroundColor: "#C30505",
                            color: colors.grey[0],
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "0 20px 20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[0],
                                color: "#C30505",
                            },
                            ".disabled": {
                                color: colors.grey[300],
                            },
                        }}
                    >
                        End
                    </Button>
                    <Button
                        disabled={false}
                        onClick={onClickReset}
                        sx={{
                            backgroundColor: colors.grey[500],
                            color: colors.grey[0],
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "0 20px 20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[0],
                                color: colors.grey[500],
                            },
                            ".disabled": {
                                color: colors.grey[300],
                            },
                        }}
                    >
                        Reset All Value
                    </Button>
                </Box>
                <Box
                    m="20px 0 0 0"
                >
                    <Button
                        disabled={testedCount !== totalPoint || !end}
                        onClick={onClickPrint}
                        sx={{
                            backgroundColor: "#212883",
                            color: colors.grey[0],
                            fontSize: "16px",
                            fontWeight: "500",
                            textTransform: "none",
                            padding: "5px 15px",
                            margin: "0 20px 20px 0",
                            ":hover": {
                                backgroundColor: colors.grey[0],
                                color: "#212883",
                            },
                            ".disabled": {
                                color: colors.grey[300],
                            },
                        }}
                    >
                        Print to PDF
                    </Button>
                </Box>
            </GridFooterContainer>
        );
    };

    return (
        <Box>
            {/* LOADING SPINNER */}
            {loading ? <Box
                sx={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    transform: "translate(0, 0)",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fffffff5",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    ml: "0",
                    zIndex: 3,
                    // borderRadius: "20px",
                }}
            >
                <CircularProgress
                    color="info"
                    size={70}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 45px)",
                        left: "calc(50% - 45px)",
                    }}
                />
                <Typography
                    id="progress description"
                    variant="h4"
                    color={colors.blueAccent[500]}
                    fontWeight="normal"
                    sx={{
                        position: "absolute",
                        top: "calc(75% - 25px)",
                        left: "calc(50% - 45px)",
                    }}
                >
                    Loading
                </Typography>
            </Box> : null}
            {/* MODAL */}
            <Modal
                open={modal.show}
                onClose={handleModalClose}
                aria-labelledby="title"
                aria-describedby="description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 490,
                        backgroundColor: colors.primary[400],
                        border: `2px solid ${colors.primary[600]}`,
                        borderRadius: 5,
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 2,
                    }}
                >
                    {currentModal}
                </Box>
            </Modal>
            {/* HEADER */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                px="20px"
            >
                <Header
                    title="Unit Testing"
                    subtitle=""
                />
            </Box>
            {/* BODY */}
            <Box
                px="20px"
            >
                {/* TITLE & SUB TITLE */}
                <Box
                    py="30px"
                >
                    <Box>
                        <Typography
                            variant="h3"
                            color={colors.grey[500]}
                            fontWeight="600"
                            textAlign="center"
                            mb="5px"
                        >
                            {unitData[0]?.interfaceName}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="h1"
                            color={colors.grey[100]}
                            fontWeight="600"
                            textAlign="center"
                            mb="5px"
                        >
                            {unitData[0]?.unitName}
                        </Typography>
                    </Box>
                </Box>
                {/* TABLE */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flex="1"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            lineHeight: "normal",
                            color: colors.grey[100],
                            fontSize: ".9rem",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            lineHeight: "normal",
                            color: colors.grey[100],
                            fontSize: ".9rem",
                        },
                        "& .MuiDataGrid-row": {
                            backgroundColor: "transparent",
                            borderBottom: `1px solid ${colors.grey[800]}`,
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "transparent",
                            borderBottom: `3px solid ${colors.grey[800]}`,
                            color: colors.grey[100],
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                            wordWrap: "break-word",
                            whiteSpace: "normal",
                            lineHeight: "normal",
                            fontSize: ".9rem",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: "transparent",
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            backgroundColor: "transparent",
                        },
                        "& .MuiCheckbox-root": {
                            color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${colors.grey[100]} !important`,
                        },
                    }}
                >
                    <DataGrid
                        apiRef={apiRef}
                        getRowId={(row) => row.pointId}
                        density="standard"
                        // hideFooter
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        rows={rows}
                        columns={columns}
                        columnVisibilityModel={{
                            // pointId: false,
                            pointName: false,
                            typeNumber: false,
                            channelNumber: false,
                            action: (!isPrint)
                        }}
                        slots={{
                            // toolbar: GridToolbar,
                            footer: customFooter
                        }}
                        height="auto"
                        sx={{
                            width: "100%",
                        }}
                        disableVirtualization
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default UnitTesting;

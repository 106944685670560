
export const fetchServerData = (serverFilePath = '') => {
    if (serverFilePath !== '') {
        console.log("Fetch URL: " + process.env.REACT_APP_API_URL + 'json/' + serverFilePath);

        return fetch(process.env.REACT_APP_API_URL + 'json/' + serverFilePath, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                // "Authorization": `Basic ${btoa("developer:kodedigital")}`,
            },
        })
            .then(response => {
                let result = response.json();

                console.log("fetchServerData Result:");
                console.log(JSON.stringify(result));

                return result;
            })
            .catch(error => {
                console.log("fetchServerData Error:");
                console.log(JSON.stringify(error));

                return {"result": "error", "reason": JSON.stringify(error)};
            });
    } else {
        return new Promise((resolve) => {
            resolve({"result": "error", "reason": "Server File Path Empty!"});
        });
    }
};

export const convertServerData = () => {
    let currentServerChannelId = JSON.parse(sessionStorage.getItem("currentServerChannelId"));
    if (currentServerChannelId == null) {
        currentServerChannelId = 4;
        sessionStorage.setItem("previousServerChannelId", currentServerChannelId);
        sessionStorage.setItem("currentServerChannelId", currentServerChannelId);
    }
    let serverFilePath = 'dots_' + currentServerChannelId + '.json';

    return fetchServerData(serverFilePath).then(data => {
        const newConvertedData = [];

        if (data.result !== "error") {
            for (let key in data) {
                let pointId = data[key].description;
                let tempArr = pointId.split("\\");
                let interfaceName = tempArr[0];
                let unitId = tempArr[0] + '\\' + tempArr[1];
                let unitName = tempArr[1];
                let pointName = '';
                if (tempArr.length > 2) {
                    for (let i = 2; i < tempArr.length; i++) {
                        if (pointName === '') {
                            pointName = tempArr[i];
                        } else {
                            pointName += '\\' + tempArr[i];
                        }
                    }
                } else {
                    pointName = tempArr[tempArr.length - 1];
                }
                let type = (data[key]?.objecttype !== null && data[key]?.objecttype !== "") ? data[key]?.objecttype : 255;

                let existData = newConvertedData.find(newData => newData.unitId === unitId);
                if (!existData) {
                    let newData = {
                        channelId: data[key].channelId,
                        deviceId: data[key].deviceId,
                        interfaceName: interfaceName,
                        unitId: unitId,
                        unitName: unitName,
                        points: [
                            {
                                pointId: pointId,
                                pointName: pointName,
                                type: type,
                                initialValue: "",
                                initialTimestamp: "",
                                testValue: "",
                                testTimestamp: "",
                                finalValue: "",
                                finalTimestamp: "",
                                started: false,
                                tested: false,
                                testResult: "",
                                ended: false,
                            }
                        ],
                        start: false,
                        end: false,
                    };

                    newConvertedData.push(newData);

                } else {
                    newConvertedData.find(newData => newData.unitId === unitId).points.push({
                        pointId: pointId,
                        pointName: pointName,
                        type: type,
                        initialValue: "",
                        initialTimestamp: "",
                        testValue: "",
                        testTimestamp: "",
                        finalValue: "",
                        finalTimestamp: "",
                        started: false,
                        tested: false,
                        testResult: "",
                        ended: false,
                    });
                }
            }
        }

        sessionStorage.setItem("serverFatData", JSON.stringify(newConvertedData));

        return newConvertedData;
    });
};

export const getDataById = ( id= '', target = 'server', fresh = false ) => {
    if (id !== '') {
        // Return data filtered by id
        let localFatData = JSON.parse(sessionStorage.getItem("localFatData"));

        if (localFatData === null || localFatData === undefined || localFatData.length === 0 || localFatData.filter(data => data.unitId === id).length === 0) {
            let serverFatData = JSON.parse(sessionStorage.getItem("serverFatData"));

            if (serverFatData === null || serverFatData === undefined || serverFatData.length === 0) {
                return convertServerData().then(serverData => {
                    serverData = serverData.filter(data => data.unitId === id);

                    return (serverData.filter(data => data.unitId === id));
                });
            } else {
                return new Promise((resolve) => {
                    resolve(serverFatData.filter(data => data.unitId === id));
                });
            }
        } else {
            return new Promise((resolve) => {
                resolve(localFatData.filter(data => data.unitId === id));
            });
        }
    } else {
        // Return all data
        if (target === 'server') {
            // server data
            let previousServerChannelId = JSON.parse(sessionStorage.getItem("previousServerChannelId"));
            let currentServerChannelId = JSON.parse(sessionStorage.getItem("currentServerChannelId"));

            if (previousServerChannelId != currentServerChannelId || fresh) {
                return convertServerData().then(serverData => {
                    sessionStorage.setItem("previousServerChannelId", JSON.stringify(currentServerChannelId));

                    return (serverData);
                });
            } else {
                let serverFatData = JSON.parse(sessionStorage.getItem("serverFatData"));

                if (serverFatData === null || serverFatData === undefined || serverFatData.length === 0) {
                    return convertServerData().then(serverData => {
                        return (serverData);
                    });
                } else {
                    return new Promise((resolve) => {
                        resolve(serverFatData);
                    });
                }
            }
        } else {
            // local tested data
            let localFatData = JSON.parse(sessionStorage.getItem("localFatData"));

            if (localFatData === null || localFatData === undefined || localFatData.length === 0 || fresh) {
                return getLocalData().then(localData => {
                    return (localData);
                });
            } else {
                return new Promise((resolve) => {
                    resolve(localFatData);
                });
            }
        }
    }
};

export const getLocalData = () => {
    let serverFilePath = process.env.REACT_APP_LOCAL_DATA_FILENAME; // 'localFatData.json'

    return fetchServerData(serverFilePath).then(data => {
        let newConvertedData = [];

        console.log("Get Local Data result: " + JSON.stringify(data));

        if (data.result !== "error") {
            newConvertedData = data;
        }

        sessionStorage.setItem("localFatData", JSON.stringify(newConvertedData));

        return newConvertedData;
    });
};

export const setDataById = ( id = '', unitData = [] ) => {
    if (id !== '' && unitData.length > 0) {
        let localFatData = JSON.parse(sessionStorage.getItem("localFatData"));

        console.log("setDataById localFatData: ", localFatData);
        console.log("setDataById unitData[0]: ", unitData[0]);

        if (localFatData === null || localFatData === undefined || localFatData.length === 0) {
            const newConvertedData = [];
            newConvertedData.push(unitData[0]);

            sessionStorage.setItem("localFatData", JSON.stringify(newConvertedData));
        } else {
            let exists = false;

            for (let i = 0; i < localFatData.length; i++) {
                if (localFatData[i].unitId === id) {
                    localFatData[i] = unitData[0];
                    exists = true;

                    break;
                }
            }

            console.log("setDataById exists: ", exists);

            if (exists === false) {
                localFatData.push(unitData[0]);
            }

            sessionStorage.setItem("localFatData", JSON.stringify(localFatData));
        }

        saveData("localFatData", process.env.REACT_APP_LOCAL_DATA_FILENAME); // 'localFatData.json'
    }
};

export const saveData = (sessionKey = '', serverFilePath = '') => {
    if (sessionKey !== '' && serverFilePath !== '') {
        let sessionData = sessionStorage.getItem(sessionKey);

        console.log("saveData sessionData: ", sessionData);

        if (sessionData != null) {
            return fetch(process.env.REACT_APP_API_URL + 'cgi-bin?action=savefile&filename=/FAT/' + serverFilePath, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                },
                body: sessionData
            })
                .then(response => {
                    let result = response.json();

                    console.log("saveData Result:");
                    console.log(JSON.stringify(result));

                    return result;
                })
                .catch(error => {
                    console.log("saveData Error:");
                    console.log(JSON.stringify(error));

                    return {"result": "error", "reason": JSON.stringify(error)};
                });
        } else {
            console.log("saveData Error: session data empty!");

            return new Promise((resolve) => {
                resolve({"result": "error", "reason": "Session Data Empty!"});
            });
        }
    } else {
        console.log("saveData Error: server file path empty!");

        return new Promise((resolve) => {
            resolve({"result": "error", "reason": "Server File Path Empty!"});
        });
    }
};

export const getPollData = (queryData) => {
    let readURL = process.env.REACT_APP_API_URL + process.env.REACT_APP_READ_API_URL;

    // Generate data query
    let dataStr = '[';
    for (let i = 0; i < queryData.length; i++) {
        dataStr = dataStr + '{"Channel":' + queryData[i].Channel + ',"Point":"' + escapeSlash(queryData[i].Point) + '"}';
        if ((i+1) !== queryData.length) {
            dataStr = dataStr + ',';
        }
    }
    dataStr = dataStr + ']';

    // Embed data query in the url
    readURL = readURL.replace(/%DATA_PLACEHOLDER%/g, dataStr);

    console.log("getPollData URL: " + readURL);

    return fetch(readURL, {
        method: "GET",
        headers: {
            "Accept": "application/json",
        },
    })
        .then(response => {
            let result = response.json();

            console.log("getPollData Result:");
            console.log(JSON.stringify(result));

            return result;
        })
        .catch(error => {
            console.log("getPollData Error:");
            console.log(JSON.stringify(error));

            return {"result": "error", "reason": JSON.stringify(error)};
        });
};

export const setPollData = (channel, point, value) => {
    let writeURL = process.env.REACT_APP_API_URL + process.env.REACT_APP_WRITE_API_URL;

    // Generate data query
    let dataStr = '{"channel":' + channel + ',"point":"' + escapeSlash(point) + '","value":"' + value + '"}';

    // Embed data query in the url
    writeURL = writeURL.replace(/%DATA_PLACEHOLDER%/g, dataStr);

    console.log("setPollData URL: " + writeURL);

    return fetch(writeURL, {
        method: "GET",
        headers: {
            "Accept": "application/json",
        },
    })
        .then(response => {
            let result = response.json();

            console.log("setPollData Result:");
            console.log(JSON.stringify(result));

            return result;
        })
        .catch(error => {
            console.log("setPollData Error:");
            console.log(JSON.stringify(error));

            return {"result": "error", "reason": JSON.stringify(error)};
        });
};

export const getDigitalOutputPollData = (channel, point, value) => {
    return setPollData(channel, point, value).then(result => {
        console.log("getDigitalOutputPollData: " + JSON.stringify(result));

        if (result.reason === "Time Out" && result.Data?.status !== "error") {
            return true;
        } else {
            return false;
        }
    });
};

export const getAnalogOutputPollData = (channel, point, value) => {
    return setPollData(channel, point, value).then(result => {
        console.log("getAnalogOutputPollData: " + JSON.stringify(result));

        if (result.reason === "Time Out" && result.Data?.status !== "error") {
            return true;
        } else {
            return false;
        }
    });
};

export const getChannelInfo = () => {
    console.log("getChannelInfo URL: " + process.env.REACT_APP_API_URL + 'json/channels.json');

    return fetch(process.env.REACT_APP_API_URL + 'json/channels.json', {
        method: "GET",
        headers: {
            "Accept": "application/json",
        },
    })
        .then(response => {
            let result = response.json();

            console.log("getChannelInfo Result:");
            console.log(JSON.stringify(result));

            return result;
        })
        .catch(error => {
            console.log("getChannelInfo Error:");
            console.log(JSON.stringify(error));

            return {"result": "error", "reason": JSON.stringify(error)};
        });
};

export const getSetting = () => {
    let serverFilePath = process.env.REACT_APP_LOCAL_SETTING_FILENAME; // 'localFatSetting.json'

    return fetchServerData(serverFilePath).then(data => {
        let newSettingData = [];
        let previousServerChannelId = "";
        let currentServerChannelId = 4;
        let previousFailurePercent = "";
        let currentFailurePercent = 5;

        console.log("Get Setting Result: " + JSON.stringify(data));

        if (data.result !== "error") {
            previousServerChannelId = (data?.previousServerChannelId ?? "");
            currentServerChannelId = (data?.currentServerChannelId ?? 4);
            previousFailurePercent = (data?.previousFailurePercent ?? "");
            currentFailurePercent = (data?.currentFailurePercent ?? 5);

            newSettingData = [{
                "previousServerChannelId": previousServerChannelId,
                "currentServerChannelId": currentServerChannelId,
                "previousFailurePercent": previousFailurePercent,
                "currentFailurePercent": currentFailurePercent
            }];
        }

        sessionStorage.setItem("Get Setting localFatSetting", JSON.stringify(newSettingData));
        sessionStorage.setItem("Get Setting previousServerChannelId", JSON.stringify(previousServerChannelId));
        sessionStorage.setItem("Get Setting currentServerChannelId", JSON.stringify(currentServerChannelId));
        sessionStorage.setItem("Get Setting previousFailurePercent", JSON.stringify(previousFailurePercent));
        sessionStorage.setItem("Get Setting currentFailurePercent", JSON.stringify(currentFailurePercent));

        return newSettingData;
    });
};

export const setSetting = () => {
    const newSettingData = [{
        "previousServerChannelId": JSON.parse(sessionStorage.getItem("previousServerChannelId")),
        "currentServerChannelId": JSON.parse(sessionStorage.getItem("currentServerChannelId")),
        "previousFailurePercent": JSON.parse(sessionStorage.getItem("previousFailurePercent")),
        "currentFailurePercent": JSON.parse(sessionStorage.getItem("currentFailurePercent"))
    }];

    sessionStorage.setItem("localFatSetting", JSON.stringify(newSettingData));

    console.log("Set Setting localFatSetting: ", newSettingData);

    return saveData("localFatSetting", "localFatSetting.json");
};

export const formatDate = ( dt = null ) => {
    if (!!dt) {
        let month = dt.toLocaleString('en-US', {month: "long"});
        let day = String(dt.getDate()).padStart(2, "0");
        let year = dt.getFullYear();
        let hour = String(((dt.getHours() + 11) % 12) + 1).padStart(2, "0");
        let minutes = String(dt.getMinutes()).padStart(2, "0");
        let seconds = String(dt.getSeconds()).padStart(2, "0");
        let suffix = (dt.getHours() < 12) ? 'AM' : 'PM';

        return day+' '+month+' '+year+' '+hour+':'+minutes+':'+seconds+' '+suffix;
    } else {
        return dt;
    }
};

export const getTypeDisplay = (type = '', format = 'short') => {
    /*
        - 255 = undefined
		- 0 = Analog Input (AI)
		- 1 = Analog Output (AO)
		- 2 = Analog Value (AV)
		- 3 = Binary Input (BI)
		- 4 = Binary Output (BO)
		- 5 = Binary Value (BV)
     */
    switch(type) {
        case 0:
            return ((format === "short") ? "AI" : "Analog Input (AI)");
        case 1:
            return ((format === "short") ? "AO" : "Analog Output (AO)");
        case 2:
            return ((format === "short") ? "AV" : "Analog Value (AV)");
        case 3:
            return ((format === "short") ? "BI" : "Binary Input (BI)");
        case 4:
            return ((format === "short") ? "BO" : "Binary Output (BO)");
        case 5:
            return ((format === "short") ? "BV" : "Binary Value (BV)");
        default:
            return "N/A";
    }
};

export const getValueDisplay = (type = '', value = '') => {
    /*
        - 255 = undefined
		- 0 = Analog Input (AI)
		- 1 = Analog Output (AO)
		- 2 = Analog Value (AV)
		- 3 = Binary Input (BI)
		- 4 = Binary Output (BO)
		- 5 = Binary Value (BV)
     */
    if (value !== '' && !isNaN(value)) {
        switch (type) {
            case 0:
                return parseFloat(value).toFixed(1);
            case 1:
                return parseFloat(value).toFixed(1);
            case 2:
                return parseFloat(value).toFixed(1);
            case 3:
                return ((value > 0) ? "ON, " : "OFF, ") + parseFloat(value).toFixed(0);
            case 4:
                return ((value > 0) ? "ON, " : "OFF, ") + parseFloat(value).toFixed(0);
            case 5:
                return ((value > 0) ? "ON, " : "OFF, ") + parseFloat(value).toFixed(0);
            default:
                return value;
        }
    } else {
        return value;
    }
}

export const sleep = async (ms = 1000) => {
    return await new Promise(resolve => setTimeout(resolve, ms));
};

export const escapeSlash = (str) => {
    return JSON.stringify(str).replace(/((^")|("$))/g, "").trim()
};

export const calculateProgress = (data) => {
    if (!!data) {
        let startedCount = (!!data?.points?.filter(data => data.started === true).length) ? data?.points?.filter(data => data.started === true).length : 0;
        let testedCount = (!!data?.points?.filter(data => data.tested === true).length) ? data?.points?.filter(data => data.tested === true).length : 0;
        let endedCount = (!!data?.points?.filter(data => data.ended === true).length) ? data?.points?.filter(data => data.ended === true).length : 0;
        let totalCount = (!!data?.points?.length) ? data?.points?.length : 0;
        let progress = ((startedCount + testedCount + endedCount) / (totalCount * 3));

        return progress.toFixed(2);
    }
}

import { useState } from "react";
import {Box, Button, CircularProgress, TextField, Typography, useTheme} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { tokens } from "../theme";
import { setSetting } from "../utils/helper";

const FailureRange = ({ handleFailureRangeChange }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(false);
    const currentFailurePercent = JSON.parse(sessionStorage.getItem("currentFailurePercent"));

    const checkoutSchema = yup.object().shape({
        failurePercent: yup
            .number()
            .required("required")
            .min(1, 'Minimum Failure Percentage'),
    });

    const initialValues = {
        failurePercent: ((currentFailurePercent !== null && currentFailurePercent !== "") ? currentFailurePercent : 5),
    }

    const handleFormSubmit = (values) => {
        setLoading(true);

        console.log("FailureRange: " + values.failurePercent);

        sessionStorage.setItem("previousFailurePercent", currentFailurePercent);
        sessionStorage.setItem("currentFailurePercent", values.failurePercent);

        setSetting().then(data => {
            setLoading(false);
            handleFailureRangeChange(values.failurePercent);
        });
    };

    return (
        <Box>
            {/* LOADING SPINNER */}
            {loading ? <Box
                sx={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    transform: "translate(0, 0)",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fffffff5",
                    pt: 2,
                    px: 4,
                    pb: 3,
                    ml: "0",
                    zIndex: 3,
                    borderRadius: "20px",
                }}
            >
                <CircularProgress
                    color="info"
                    size={70}
                    sx={{
                        position: "absolute",
                        top: "calc(50% - 45px)",
                        left: "calc(50% - 45px)",
                    }}
                />
                <Typography
                    id="progress description"
                    variant="h4"
                    color={colors.blueAccent[500]}
                    fontWeight="normal"
                    sx={{
                        position: "absolute",
                        top: "calc(75% - 25px)",
                        left: "calc(50% - 45px)",
                    }}
                >
                    Loading
                </Typography>
            </Box> : null}
            {/* HEADER */}
            <Box
                // mb="30px"
                backgroundColor={colors.primary[400]}
                p="15px"
            >
                <Typography
                    id="title"
                    variant="h4"
                    color={colors.grey[100]}
                    fontWeight="normal"
                    sx={{ m: "0 0 5px 0" }}
                >
                    Failure Range
                </Typography>
                <Typography
                    id="description"
                    variant="h6"
                    color={colors.greenAccent[400]}
                >
                    Value changes that fall in between the range will be considered fail.
                </Typography>
            </Box>
            {/* BODY */}
            <Box
                p="15px"
            >
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {(props) => {
                        return (
                            <Form>
                                <TextField
                                    label="Failure Percent"
                                    name="failurePercent"
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    value={props.values.failurePercent}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    helperText={<ErrorMessage name="failurePercent" />}
                                    error={props.errors.failurePercent && props.touched.failurePercent}
                                    required
                                />

                                <Button
                                    // variant="contained"
                                    type="submit"
                                    // color="primary"
                                    fullWidth
                                    sx={{
                                        // my: "15px"
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "5px 15px",
                                        textTransform: "uppercase",
                                        ":hover": {
                                            backgroundColor: colors.grey[100],
                                            color: colors.blueAccent[700],
                                        },
                                    }}
                                >
                                    UPDATE
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};

export default FailureRange;
